import React from 'react';

interface Props {
    children: string;
    style?: React.CSSProperties;
}

const NoEscape: React.FC<Props> = ({children, style}) => {

    return <div dangerouslySetInnerHTML={{__html: children}} style={style}/>;
}

export default NoEscape;