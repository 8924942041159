import React from 'react';
import Navbar from "../components/Navbar/Navbar";
import Slideshow from "../components/Base/Slideshow/Slideshow";
import ContentBlock from "../components/Content/ContentBlock";
import {TitleType} from "../enums/TitleType.enum";
import Noescape from "../components/Base/Noescape";
import WineListWrapper from "../components/WineList/WineListWrapper";
import Link from "../components/Base/Link/Link";
import {Theme} from "../enums/Theme.enum";

interface Props {

}

const content = {
    block1: {
        icon: {
            file: 'grapes.svg',
            title: 'grapes icon'
        },
        title: {
            content: 'Vinotéka Perkmistr',
            type: TitleType.H1
        },
        text: 'Vinotéka Perkmistr je rodinný podnik s dlouholetou tradicí, který si získal oblibu mezi milovníky vína díky své pečlivosti a nadšení pro kvalitní produkty. Její pobočky najdete na třech místech – v Brně, Kuřimi a Tišnově.<br><br>V nabídce Vinotéky Perkmistr najdete širokou škálu stáčených i lahvových vín, která uspokojí jak běžné zákazníky, tak i opravdové znalce. Výběr vín je pečlivě sestaven s ohledem na rozmanité chutě i příležitosti, ať už hledáte víno pro slavnostní příležitost, nebo jen sklenku k příjemnému večeru. Na všech prodejnách je kromě vín k dispozici také oblíbené pivo Kvasar, známé svou výjimečnou chutí, a různé pochutiny, které jsou ideálním doplňkem k vínu.<br><br>Pobočky Vinotéky Perkmistr navíc fungují jako výdejní místa sítě Zásilkovna, což z nich činí nejen místo pro nákup kvalitního vína, ale také pohodlnou volbu pro vyzvednutí zásilek.'
    },
    block2: {
        icon: {
            file: 'wine-glass.svg',
            title: 'wine glass icon'
        },
        title: {
            content: 'Nabídka vín',
            type: TitleType.H2
        },
        text: 'Vinotéka Nad Schody nabízí široký výběr bílých i červených vín, a to jak čepovaných, tak i lahvových. Mezi nabízenými druhy vín naleznete například Ryzlink Rýnský, Sauvignon, Pálavu nebo oblíbené Chardonnay. Každé víno je pečlivě vybíráno a skladováno, aby bylo vždy připraveno na podávání v té nejlepší kvalitě. Pokud hledáte skvělé víno, které dokáže podtrhnout každou příležitost, určitě si u nás přijdete na své.'
    },
    block3: {
        icon: {
            file: 'clock-light.svg',
            title: 'wine glass icon'
        },
        title: {
            content: 'Otevírací doba',
            type: TitleType.H2
        },
    },
    block4: {
        icon: {
            file: 'phone-light.svg',
            title: 'phone icon'
        },
        title: {
            content: 'Kontakt',
            type: TitleType.H2
        },
    }
}


const workplaces: {
    id: string;
    title: string;
    address: string;
    image: string;
    officeCode?: number;
    contact: {
        phoneNumber: string;
        email: string;
    };
    provider: {
        name: string;
        ico: string;
        dic: string;
        address: string;
        extra?: string;
    };
    workingHours: {
        monday: string;
        tuesday: string;
        wednesday: string;
        thursday: string;
        friday: string;
        saturday: string;
        sunday: string;
    }
}[] = [
    {
        id: 'brno',
        title: 'Pobočka Brno',
        address: 'Dusíkova 17, Brno 63800',
        image: '/images/wineGlasses.webp',
        officeCode: 11,
        contact: {
            phoneNumber: '+420 530 335 511',
            email: 'brno@vinotekaperkmistr.cz',
        },
        provider: {
            name: 'GDI Corporation s.r.o.',
            ico: '29187958',
            dic: 'CZ29187958',
            address: 'Schodová 1291/17, Brno 60200',
            extra: 'Společnost je zapsána v obchodním rejstříku vedeném<br/>Krajským soudem v Brně oddíl C vložka 64186.'
        },
        workingHours: {
            monday: '11:00 - 20:00',
            tuesday: '11:00 - 20:00',
            wednesday: '11:00 - 20:00',
            thursday: '11:00 - 20:00',
            friday: '11:00 - 20:00',
            saturday: '11:00 - 20:00',
            sunday: '14:00 - 20:00',
        }
    },
    {
        id: 'kurim',
        title: 'Pobočka Kuřim',
        address: 'Tyršova 52, Kuřim 66434',
        image: '/images/bottles.webp',
        officeCode: 1,
        contact: {
            phoneNumber: '+420 530 350 447',
            email: 'kurim@vinotekaperkmistr.cz',
        },
        provider: {
            name: 'Eva Šlesingerová',
            ico: '49486870',
            dic: 'CZ7057193748',
            address: 'Černopolní 246/55, Brno 60200',
        },
        workingHours: {
            monday: '09:00 - 19:00',
            tuesday: '09:00 - 19:00',
            wednesday: '09:00 - 19:00',
            thursday: '09:00 - 19:00',
            friday: '09:00 - 19:00',
            saturday: '09:00 - 19:00',
            sunday: '14:00 - 19:00',
        }
    },
    {
        id: 'tisnov',
        title: 'Pobočka Tišnov',
        address: 'Nádražní 1917, Tišnov 66601',
        image: '/images/wineyard.webp',
        contact: {
            phoneNumber: '+420 601 201 833',
            email: 'tisnov@vinotekaperkmistr.cz',
        },
        provider: {
            name: 'Eva Šlesingerová',
            ico: '49486870',
            dic: 'CZ7057193748',
            address: 'Černopolní 246/55, Brno 60200',
        },
        workingHours: {
            monday: '09:00 - 19:00',
            tuesday: '09:00 - 19:00',
            wednesday: '09:00 - 19:00',
            thursday: '09:00 - 19:00',
            friday: '09:00 - 19:00',
            saturday: '09:00 - 19:00',
            sunday: '14:00 - 19:00',
        }
    }
]

const Homepage: React.FC<Props> = props => {
    return (
        <>
            <Slideshow>
                <Navbar/>
            </Slideshow>
            <div className='container mx-auto'>
                <ContentBlock icon={content.block1.icon} title={content.block1.title} text={content.block1.text}/>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                    {workplaces.map((workplace, key) => (
                        <div id={workplace.id} key={key}>
                            <img src={workplace.image} alt={workplace.title}/>
                            <ContentBlock title={{content: workplace.title, type: TitleType.H2}}>
                                <div className='flex flex-col gap-2 items-center'>
                                    <div>{workplace.address}</div>
                                    <div>tel.: <Link
                                        href={`tel:${workplace.contact.phoneNumber}`} title={workplace.contact.phoneNumber} theme={Theme.GREEN}/>
                                    </div>
                                    <div>email.: <Link
                                        href={`mailto:${workplace.contact.email}`} title={workplace.contact.email} theme={Theme.GREEN}/>
                                    </div>
                                </div>
                                <div className='flex flex-col gap-2 items-center'>
                                    <div><b>Otevírací doba</b></div>
                                    <div>Pondělí {workplace.workingHours.monday}</div>
                                    <div>Úterý {workplace.workingHours.tuesday}</div>
                                    <div>Středa {workplace.workingHours.wednesday}</div>
                                    <div>Čtvrtek {workplace.workingHours.thursday}</div>
                                    <div>Pátek {workplace.workingHours.friday}</div>
                                    <div>Sobota {workplace.workingHours.saturday}</div>
                                    <div>Neděle {workplace.workingHours.sunday}</div>
                                </div>
                                <div className='flex flex-col gap-2 items-center'>
                                    <div><b>Provozovatel</b></div>
                                    <div>{workplace.provider.name}</div>
                                    <div>IČO: {workplace.provider.ico}</div>
                                    <div>DIČ: {workplace.provider.dic}</div>
                                    <div>{workplace.provider.address}</div>
                                    {workplace.provider.extra && (
                                        <Noescape style={{textAlign: 'center'}}>{workplace.provider.extra}</Noescape>)}
                                </div>
                                {workplace.officeCode && (
                                    <div className='flex flex-col gap-2 items-center'>
                                        <WineListWrapper id={workplace.officeCode}/>
                                    </div>
                                )}
                            </ContentBlock>
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}

export default Homepage;