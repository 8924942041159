import React from "react";
import {WineMenuItem} from "../../containers/AppContext";
import styles from "./WineListItem.module.css";

interface Props {
    item: WineMenuItem;
}

const WineListItem: React.FC<Props> = ({item}) => {
    return (
        <tr>
            <td className={styles.title}>
                {item.title}
                {item.subTitle && <span className={styles.subtitle}> ({item.subTitle})</span>}
            </td>
            <td className={styles.sugarLevel}>{item.sugarLevel}</td>
            <td className={styles.price}>{item.price} Kč / 1l</td>
        </tr>
    )
}

export default WineListItem;