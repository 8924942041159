import React from "react";
import {WineMenuCategory} from "../../containers/AppContext";
import WineListCategory from "./WineListCategory";
import styles from "./WineList.module.css";

interface Props {
    data: WineMenuCategory[];
}

const WineList: React.FC<Props> = ({data}) => {

    return (
        <div className={styles.wrapper}>
            {data?.length && data.map((item) => {
                return <WineListCategory item={item}/>
            })}
        </div>
    )
}

export default WineList;