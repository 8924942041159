import React from 'react';
import WineScreenLayout from "../components/WineScreenLayout/WineScreenLayout";

interface Props{

}

const WineScreen: React.FC<Props> = props => {
    return <WineScreenLayout/>
}

export default WineScreen;