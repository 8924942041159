import React from 'react';
import styles from "./Link.module.css";
import {Theme} from "../../../enums/Theme.enum";
interface Props{
    title: string;
    href: string;
    theme: Theme;
}

const Link: React.FC<Props> = ({title, href, theme}) => {
    return (
        <a className={[styles.link, styles[theme]].join(' ')} href={href}>{title}</a>
    )
}

export default Link;