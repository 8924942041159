import React from 'react';
import styles from "./Slideshow.module.css";

interface Props{
    children: React.ReactNode | React.ReactNode[];
}

const Slideshow: React.FC<Props> = ({children}) => {
    return (
        <div className={styles.slide}>
            {children}
        </div>
    )
}

export default Slideshow;