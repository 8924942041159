import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import Homepage from "./containers/Homepage";
import WineScreen from "./containers/WineScreen";
import {AppContextProvider} from "./containers/AppContext";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
const router = createBrowserRouter([
    {
        path: "/",
        element: <Homepage/>
    },
    {
        path: "/wine-screen",
        element: <WineScreen/>
    },
]);
root.render(
  <React.StrictMode>
      <AppContextProvider>
        <RouterProvider router={router} />
      </AppContextProvider>
  </React.StrictMode>
);

reportWebVitals();
