import React, {useState} from "react";
import {SugarLevel} from "../enums/SugarLevel.enum";
import {WineType} from "../enums/WineType.enum";

export interface WineMenuItem {
    title: string;
    subTitle?: string;
    sugarLevel: SugarLevel;
    price: number;
}

export interface WineMenuCategory {
    title: string;
    type: WineType;
    items: WineMenuItem[];
}

export interface AppContextProps {
    wineMenu?: WineMenuCategory[];
    setWineMenu: (newState: WineMenuCategory[]) => void;
}

export interface AppContextProviderProps {
    values?: AppContextProps;
    children?: React.ReactChild | React.ReactChild[];
}

export const AppContext = React.createContext<AppContextProps>({
    wineMenu: undefined,
    setWineMenu: () => {
    },
});

export const useAppContext: () => AppContextProps = () =>
    React.useContext<AppContextProps>(AppContext);

export function AppContextProvider({
                                       values,
                                       children,
                                   }: AppContextProviderProps) {
    const [wineMenu, setWineMenu] = useState<WineMenuCategory[]>(
        [
            {
                title: "Bílá vína",
                type: WineType.WHITE,
                items: [
                    {
                        title: "Rizling vlašský",
                        price: 82,
                        sugarLevel: SugarLevel.SUCHE,
                    },
                    {
                        title: "Veltlínské zelené",
                        price: 82,
                        sugarLevel: SugarLevel.SUCHE,
                    },
                    {
                        title: "Pinot Gris",
                        subTitle: "Rulandské šedé",
                        price: 82,
                        sugarLevel: SugarLevel.SUCHE,
                    },
                    {
                        title: "Chardonnay",
                        price: 88,
                        sugarLevel: SugarLevel.POLO_SUCHE,
                    },
                    {
                        title: "Sylvánské zelené",
                        price: 92,
                        sugarLevel: SugarLevel.POLO_SUCHE,
                    },
                    {
                        title: "Tramin červené",
                        price: 88,
                        sugarLevel: SugarLevel.POLO_SLADKE,
                    },
                    {
                        title: "Irsai Oliver",
                        price: 96,
                        sugarLevel: SugarLevel.POLO_SLADKE,
                    },
                    {
                        title: "Pálava",
                        price: 120,
                        sugarLevel: SugarLevel.POLO_SLADKE,
                    },
                ]
            },
            {
                title: "Červená vína",
                type: WineType.RED,
                items: [
                    {
                        title: "Merlot",
                        price: 88,
                        sugarLevel: SugarLevel.POLO_SLADKE,
                    },
                    {
                        title: "Dornfelder",
                        price: 106,
                        sugarLevel: SugarLevel.POLO_SUCHE,
                    },
                    {
                        title: "Cabernet Sauvignon",
                        price: 86,
                        sugarLevel: SugarLevel.SUCHE
                    },
                    {
                        title: "Primitivo",
                        price: 114,
                        sugarLevel: SugarLevel.SUCHE,
                    }
                ]
            },
            {
                title: "Růžová vína",
                type: WineType.PINK,
                items: [
                    {
                        title: "Cabernet Sauvignon rosé",
                        price: 94,
                        sugarLevel: SugarLevel.POLO_SLADKE,
                    },
                ]
            }
        ]
    );

    return (
        <AppContext.Provider
            value={{
                wineMenu,
                setWineMenu,
            }}>
            {children}
        </AppContext.Provider>
    );
}
