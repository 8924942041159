import React from "react";
import {WineMenuCategory} from "../../containers/AppContext";
import WineListItem from "./WineListItem";
import styles from "./WineListCategory.module.css";

interface Props {
    item: WineMenuCategory
    titleClassName?: string;
}

const WineListCategory: React.FC<Props> = ({item, titleClassName}) => {
    return (
        <div className={styles.wrapper}>
            <div className={[styles.title, titleClassName].join(" ")}>{item.title}</div>
            <table className="table-auto w-full">
                {item.items?.length && item.items.map((subItem, index) => {
                    return <WineListItem item={subItem} key={index}/>
                })}
            </table>
        </div>
    )
}

export default WineListCategory;