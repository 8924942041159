import React from 'react';
import styles from "./Navbar.module.css";
import Link from "../Base/Link/Link";
import {Theme} from "../../enums/Theme.enum";

interface Props{

}

const Navbar: React.FC<Props> = props => {
    return (
        <div className={'container mx-auto'}>
            <div className={styles.wrapper}>
                <div>
                    <img src='/images/logotype.png' alt='Vinotéka Permistr' style={{height: "60px"}}/>
                </div>
                <div className={styles.navigation}>
                    <Link title='Brno' href={'#brno'} theme={Theme.WHITE}/>
                    <Link title='Kuřim' href={'#kurim'} theme={Theme.WHITE}/>
                    <Link title='Tišnov' href={'#tisnov'} theme={Theme.WHITE}/>
                </div>
            </div>
        </div>
    )
}

export default Navbar;