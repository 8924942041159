import React from "react";

interface Props {
    file: string;
    title: string;
}

const Icon: React.FC<Props> = ({file, title}) => {
    return <img src={'/icons/' + file} alt={title} style={{height: "80px"}}/>
}

export default Icon;