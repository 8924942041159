import React, {useEffect, useMemo, useState} from "react";
import WineList from "./WineList";
import {WineMenuCategory, WineMenuItem} from "../../containers/AppContext";
import {WineType} from "../../enums/WineType.enum";
import {SugarLevel} from "../../enums/SugarLevel.enum";

interface Props {
    id: number;
}

const WineListWrapper: React.FC<Props> = ({id}) => {
    const [data, setData] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`https://vinotekaperkmistr.cz/televize_new/api.php?office=${id}`);
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                const result: any = await response.json();
                setData(result);
            } catch (err: any) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const getSugarEnum = (sugar: string): SugarLevel => {
        switch (sugar) {
            case 'suché':
                return SugarLevel.SUCHE;
            case 'polosladké':
                return SugarLevel.POLO_SLADKE;
            case 'polosuché':
                return SugarLevel.POLO_SUCHE;
            case 'sladké':
                return SugarLevel.SLADKE;
            default:
                return SugarLevel.SUCHE;
        }
    }

    const wineData: WineMenuCategory[] = useMemo(() => {

        const whiteWines: WineMenuItem[] = [];
        const redWines: WineMenuItem[] = [];
        const pinkWines: WineMenuItem[] = [];

        data?.data?.forEach((item: {title: string, color: string, price: number, sugar: string}) => {
            switch (item.color) {
                case '#8dc34d':
                    whiteWines.push({
                        title: item.title,
                        sugarLevel: getSugarEnum(item.sugar),
                        price: item.price
                    })
                    break;
                case '#fa0733':
                    redWines.push({
                        title: item.title,
                        sugarLevel: getSugarEnum(item.sugar),
                        price: item.price
                    });
                    break;
                case '#fc70b5':
                    pinkWines.push({
                        title: item.title,
                        sugarLevel: getSugarEnum(item.sugar),
                        price: item.price
                    });
                    break;
            }
        })

        return [
            {
                title: "Bílá vína",
                type: WineType.WHITE,
                items: whiteWines
            },
            {
                title: "Červená vína",
                type: WineType.RED,
                items: redWines
            },
            {
                title: "Růžová vína",
                type: WineType.PINK,
                items: pinkWines
            }
        ];
    }, [data])

    if(loading){
        return <div>Načítám...</div>
    }

    if(error){
        return <div>Chyba načítání dat</div>
    }



    return <WineList data={wineData}/>
}

export default WineListWrapper;